<template>
  <div>
    <div v-if="services && services.length > 0" ref="container" class="x-scroll-container w-100" @scroll="onScroll">
      <!-- Items Loop -->
      <service-card
        v-for="(service, index) in services"
        :key="index"
        :item="service"
        :community="community"
      />
      <!-- Buttons prev and next -->
      <button
        class="link-previous btn btn-icon btn-primary btn-previous"
        @click="scrollOnePage(-1)"
      >
        <b-spinner small class="spinner" />
      </button>
      <button
        class="link-next btn btn-icon btn-primary btn-next"
        :class="{ 'btn-next--loading': isLoadingNextPage }"
        @click="scrollOnePage(+1)"
      >
        <b-spinner small class="spinner" />
      </button>
    </div>
    <div v-else>
      {{ $t('no-items-available') }}
    </div>
  </div>
</template>

<script>
import ServiceCard from "@/views/apps/services/components/ServiceCard.vue";

export default {
  components: {
    ServiceCard,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
    community: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      isLoading: false,
    };
  },
  computed: {
    itemsData() {
      return this.$store.getters.communitiesOthers["services-detail"];
    },
  },
  methods: {
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.services?.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    async fetchData(force = false) {
      this.isLoading = true;
      const response = await this.$store.dispatch("getItems", {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: "services-detail",
        page: this.lastLoadedPage,
        forceAPICall: force,
        perPage: 16,
        requestConfig: {
          communityParentSlug: this.community?.slug,
          orderByDate: -1,
          isService: true,
        },
      });
      this.services = [...this.services, ...response.data];

      this.isLoading = false;
    },
  },
};
</script>

<style></style>
